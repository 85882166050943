import React from 'react';

interface Props {
  shopping?: boolean;
}
const TicketHeaders = ({ shopping }: Props) => {
  return (
    <thead
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 2
      }}>
      <tr>
        <th></th>
        <th>Plate</th>
        <th>Date Issued</th>
        <th>Date Imported</th>
        <th>Summons Number</th>
        <th>Penalty Amount</th>
        <th>Amount Outstanding</th>
        <th>{shopping ? 'Select' : 'Ignore'}</th>
        <th>Delete</th>
      </tr>
    </thead>
  );
};

export default TicketHeaders;
