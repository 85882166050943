import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { getJobs } from '../../requests/jobs.request';
import JobDetailsModal from './JobsDetailsModal';
import { WEFResponse, Job } from '../../Types';

function JobsList() {
  const [jobs, setJobs] = useState<WEFResponse<Job[]>>();
  const [selectedJob, setSelectedJob] = useState(null);

  function handleSelectedJob(job: Job) {
    setSelectedJob(job);
  }

  function handleCloseModal() {
    setSelectedJob(null);
  }

  useEffect(() => {
    getJobs().then((data) => {
      setJobs(data);
    });
  }, []);

  return (
    <div className="mt-5">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>JobType</th>
            <th>Success</th>
            <th>StartTime</th>
            <th>EndTime</th>
          </tr>
        </thead>
        <tbody style={{ cursor: 'pointer' }}>
          {jobs?.data?.map((job) => (
            <tr key={job.id} onClick={() => handleSelectedJob(job)}>
              <td>{job.jobType}</td>
              <td>{job.success}</td>
              <td>{job.startTime}</td>
              <td>{job.endTime}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedJob && <JobDetailsModal onCloseModal={handleCloseModal} selectedJob={selectedJob} />}
    </div>
  );
}

export default JobsList;
