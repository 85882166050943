import { useMutation } from '@tanstack/react-query';
import { printTickets, uploadBulkIgnoredTickets } from '../requests/ticket.request';
import { PrintTicketsRequestType } from '../schemas/ticket.schema';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

export const usePrintTickets = () => {
  return useMutation({
    mutationKey: ['printTickets'],
    mutationFn: async (data: PrintTicketsRequestType) => await printTickets(data),
    onError: (error) => {},
    onSuccess: (data) => {
      if (data instanceof Blob) {
        let file = window.URL.createObjectURL(data);
        window.open(file, '_blank');
      }
    }
  });
};

export const useUploadBulkIgnoreTicketsMutation = () => {
  return useMutation({
    mutationFn: (data: File) => {
      const formData = new FormData();
      formData.append('csvFile', data);
      return uploadBulkIgnoredTickets(formData);
    },
    onError: (error: AxiosError) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data);
    }
  });
};
