import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useAuth0 } from '@auth0/auth0-react';

import { Link } from '@tanstack/react-location';
import LogoutModal from './dialogs/LogoutDialogs';
import { Container } from 'react-bootstrap';
import { getActiveProps } from '../helpers/utils';

const routes = [
  'Home',
  'Clients',
  'Invoicing',
  'Payment',
  'Reports',
  'Print Queue',
  'Jobs',
  'Tickets'
];

function NavBar() {
  const { isAuthenticated } = useAuth0();
  return (
    <Navbar hidden={!isAuthenticated} collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand href="/home">WeFightTickets</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {routes.map((route, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={'/' + route.toLowerCase().replace(' ', '-')}
                className="text-nav"
                getActiveProps={getActiveProps}>
                {route}
              </Nav.Link>
            ))}
          </Nav>
          <Nav>
            <LogoutModal />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
