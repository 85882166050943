import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import TicketHeaders from '../Components/TicketComponents/TicketHeaders';
import Ticket from '../Components/TicketComponents/Ticket';
import { ClientTicket } from '../Types';

type Props = {
  tickets: ClientTicket[];
  toggleInclusionInTicketIdCart?: (_: string) => void;
  ticketCart?: string[] | null;
};

const TicketsTable = ({
  tickets: initialTickets,
  toggleInclusionInTicketIdCart,
  ticketCart
}: Props) => {
  let currentPlate: string;
  let plateCount: 0;
  const [tickets, setTickets] = useState(initialTickets);

  useEffect(() => {
    setTickets(initialTickets);
  }, [initialTickets]);

  const removeTicket = (ticketToRemove: ClientTicket) => {
    console.log('removing ticket from parent');
    setTickets(tickets.filter((ticket) => ticket.id !== ticketToRemove.id));
  };

  return tickets.length > 0 ? (
    <div
      style={{
        overflowX: 'hidden',
        overflowY: 'scroll',
        maxHeight: window.innerHeight / 3
      }}>
      <Table striped>
        <TicketHeaders shopping={!!ticketCart} />
        <tbody>
          {tickets.map((ticket) => {
            return (
              <Ticket
                key={ticket.id}
                ticket={ticket}
                index={plateCount}
                toggleInclusionInTicketIdCart={toggleInclusionInTicketIdCart}
                ticketCart={ticketCart}
                removeTicket={() => removeTicket(ticket)}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  ) : (
    <strong>No Tickets</strong>
  );
};

export default TicketsTable;
