import React, { useEffect, useState } from 'react';
import { useMatch, useSearch } from '@tanstack/react-location';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { customAxiosFetch, customFetch } from '../helpers/customFetch';
import { Client, DeepClient, FreshBookImportClient } from '../Types';
import {
  synchronizeFreshBookClientInRedux,
  updateClientInRedux
} from '../ReduxSlices/clientsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Stack, Tab, Tabs, Button } from 'react-bootstrap';
import ClientPlates from '../Containers/ClientPlates';
import ClientPayments from '../Containers/ClientPayments';
import ClientTickets from '../Containers/ClientTickets';
import FoundTicketsListModal from '../Components/FoundTicketsListModal';
import { setFoundTickets } from '../ReduxSlices/ticketsSlice';
import DeleteClient from '../Components/DeleteClient';
import ShowClientInFreshBooks from '../Components/Buttons/ShowClientInFreshBooks';
import ClientTags from '../Components/ClientTags';
import { setEditingPlate } from '../ReduxSlices/platesSlice';
import ClientInfo from '../Components/ClientInfo';
import EditClient from '../Components/EditClient';

const tabs = {
  plates: undefined,
  payments: undefined,
  tickets: undefined
};

type TabKeys = keyof typeof tabs;

function ClientDetailsPage() {
  const dispatch = useAppDispatch();
  const {
    params: { clientId }
  } = useMatch();

  const client = useAppSelector((state) =>
    state.clients.allClientsList.find((c) => c.id === clientId)
  );
  const foundTickets = useAppSelector((state) => state.tickets.foundTickets);

  const selectedClientId = useAppSelector((state) => state.clients.selectedClientId);
  const isClientValid = client?.id === selectedClientId;

  const { activeTab } = useSearch() as { activeTab: TabKeys };
  const isValidTab = activeTab in tabs;
  const [key, setKey] = useState<TabKeys>(isValidTab ? activeTab : 'plates');

  const [editingClient, setEditingClient] = useState(false);

  useEffect(() => {
    dispatch(setFoundTickets({ OpenDataTickets: [], isImport: false }));
    dispatch(setEditingPlate(null));
  }, []);

  useEffect(() => {
    const getClient = async () => {
      customAxiosFetch<{
        client: Client;
        delinquent?: string;
        succesfullChargeAttempts: number;
        failedChargeAttempts: number;
      }>({
        endpoint: '/Client/' + clientId,
        callback: (result) => {
          if (!result) return;
          const formattedResult = { ...result, ...result.client };
          delete formattedResult['client'];
          dispatch(updateClientInRedux(formattedResult));
        }
      });
    };
    getClient();
  }, []);

  const handleTabSelect = (selectedKey: TabKeys) => {
    setKey(selectedKey);
    window.history.pushState({}, undefined, `?activeTab=${selectedKey}`);
    return;
  };
  const tabComponents = {
    plates: <ClientPlates client={client as DeepClient} />,
    payments: <ClientPayments client={client} />,
    tickets: <ClientTickets client={client} />
  };

  const toggleEditClientModal = () => {
    setEditingClient(!editingClient);
  };

  const importFromFreshBooks = () => {
    customFetch({
      endpoint: `/Client/import-freshBooks-client/${clientId}`,
      callback: (result: { data: FreshBookImportClient }) => {
        dispatch(synchronizeFreshBookClientInRedux(result.data));
      }
    });
  };

  return client !== undefined && isClientValid ? (
    <div className="my-5">
      <Stack direction="horizontal">
        <ClientInfo client={client} />
        <Button className="ms-auto" variant="outline-secondary" onClick={toggleEditClientModal}>
          Edit
        </Button>
      </Stack>
      <ClientTags client={client} />

      <Tabs activeKey={key} onSelect={handleTabSelect} justify>
        <Tab eventKey="plates" title={<h2>Plates</h2>}></Tab>
        <Tab eventKey="payments" title={<h2>Payments</h2>}></Tab>
        <Tab eventKey="tickets" title={<h2>Tickets</h2>}></Tab>
      </Tabs>
      {tabComponents[key]}
      {!!Object.keys(foundTickets.OpenDataTickets)[0] && (
        <FoundTicketsListModal clientId={client?.id} />
      )}
      <Stack direction="horizontal" className="ms-auto mt-1" gap={2}>
        <span className="ms-auto">
          <DeleteClient />
        </span>
        <ShowClientInFreshBooks freshBooksId={client.freshbooksId} />
        <Button variant="primary" onClick={importFromFreshBooks}>
          Import from FreshBooks
        </Button>
      </Stack>
      {editingClient && (
        <EditClient
          client={client}
          show={editingClient}
          toggleEditClientModal={toggleEditClientModal}
        />
      )}
    </div>
  ) : (
    <h3 className="my-5 text-center">Loading...</h3>
  );
}
export default withAuthenticationRequired(ClientDetailsPage);
