import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import CustomDatePicker from '../Components/FormComponents/CustomDatePicker';
import AsyncSelect from 'react-select/async';
import { searchClient } from '../requests/client.request';
import { ISearchTicketRequest, searchTickets } from '../requests/ticket.request';
import { TicketSearchResponseType } from '../schemas/ticket.schema';
import { DropDownItem } from '../Types';
import TicketSearchTable from '../Components/TicketComponents/TicketsSearchTable';
import TicketPaginatedControls from '../Components/TicketComponents/TicketPaginatedControls';
import toast from 'react-hot-toast';

const settledDropDown: DropDownItem<boolean>[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

function TicketsPage() {
  const [filteredTickets, setFilteredTickets] = useState<TicketSearchResponseType>();
  const [selectedSummonNumber, setSelectedSummonNumber] = useState('');
  const [selectedClient, setSelectedClient] = useState<DropDownItem<string>>();
  const [insertedStartDate, setInsertedStartDate] = useState('');
  const [insertedEndDate, setInsertedEndDate] = useState('');
  const [selectedSettledValue, setSelectedSettledValue] = useState<DropDownItem<boolean>>();
  const [isLoading, setIsLoading] = useState(false);

  const handleInsertedStartDateChange = (event) => {
    setInsertedStartDate(event.target.value);
  };

  const handleInsertedEndDateChange = (event) => {
    setInsertedEndDate(event.target.value);
  };

  const handleSummonsNumberChange = (event) => {
    setSelectedSummonNumber(event.target.value);
  };

  const handleClientChange = (value) => {
    setSelectedClient(value);
  };

  const handleSettledChange = (option) => {
    setSelectedSettledValue(option);
  };

  let timeoutId;
  const fetchClients = async (inputValue) => {
    clearTimeout(timeoutId);
    return new Promise<DropDownItem<string>[]>((resolve, reject) => {
      timeoutId = setTimeout(async () => {
        try {
          const { data } = await searchClient(`search-term=${inputValue}`);
          const options = data?.map((client) => ({
            value: client.id,
            label: client.name
          }));
          resolve(options);
        } catch (error) {
          reject(error);
        }
      }, 1000); // Simulating 1000ms delay
    });
  };

  async function handleFormSumit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await handleSearchTickets();
      toast.success('Tickets fetched successfully.');
    } catch (error) {
      toast.error('Failed to fetch tickets.');
    }
    setIsLoading(false);
  }

  async function handleSearchTickets(pageNumber = 1) {
    const ticketSearchRequest: ISearchTicketRequest = {
      summonsNumber: selectedSummonNumber,
      clientId: selectedClient?.value,
      insertedStartDate: insertedStartDate,
      insertedEndDate: insertedEndDate,
      settled: selectedSettledValue?.value,
      pageNumber: pageNumber
    };
    const { data } = await searchTickets(ticketSearchRequest);
    setFilteredTickets(data);
  }

  return (
    <div className="mt-5">
      <Form onSubmit={handleFormSumit}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>SummonsNumber</Form.Label>
            <Form.Control
              type="text"
              value={selectedSummonNumber}
              onChange={handleSummonsNumberChange}
              placeholder="Summons Number"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Client</Form.Label>
            <AsyncSelect
              isClearable={true}
              cacheOptions={true}
              loadOptions={fetchClients}
              placeholder="Search clients..."
              onChange={handleClientChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Imported Start Date</Form.Label>
            <CustomDatePicker onChange={handleInsertedStartDateChange} value={insertedStartDate} />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Imported End Date</Form.Label>
            <CustomDatePicker onChange={handleInsertedEndDateChange} value={insertedEndDate} />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Settled</Form.Label>
            <Select
              isClearable={true}
              options={settledDropDown}
              value={selectedSettledValue}
              onChange={handleSettledChange}
              placeholder="Settled"
            />
          </Form.Group>

          <Form.Group as={Col} className="mt-2">
            <Button disabled={isLoading} className="mt-4" type="submit">
              <Spinner size="sm" hidden={!isLoading} animation="border" className="mx-1" />
              {isLoading ? 'Searching...' : 'Search'}
            </Button>
          </Form.Group>
        </Row>
      </Form>
      <TicketSearchTable tickets={filteredTickets?.tickets} />
      <TicketPaginatedControls
        currentPage={filteredTickets?.currentPage}
        pages={filteredTickets?.pages}
        onSearchTicket={handleSearchTickets}
      />
    </div>
  );
}
export default withAuthenticationRequired(TicketsPage);
