import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import clientsSlice from './ReduxSlices/clientsSlice';
import configSlice from './ReduxSlices/configSlice';
import monitoringListsSlice from './ReduxSlices/monitoringListsSlice';
import platesSlice from './ReduxSlices/platesSlice';
import resultsSlice from './ReduxSlices/resultsSlice';
import ticketsSlice from './ReduxSlices/ticketsSlice';

import { persistReducer } from 'redux-persist';
// import { createBlacklistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import errorSlice, { ERROR_SLICE_NAME } from './ReduxSlices/errorSlice';
import successSlice, { SUCCESS_SLICE_NAME } from './ReduxSlices/successSlice';

// const blackListFilter = createBlacklistFilter('config', ['auth0BearerToken']);

const combinedReducer = combineReducers({
  config: configSlice,
  clients: clientsSlice,
  monitoringLists: monitoringListsSlice,
  results: resultsSlice,
  tickets: ticketsSlice,
  plates: platesSlice,
  [ERROR_SLICE_NAME]: errorSlice,
  [SUCCESS_SLICE_NAME]: successSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage
  // transforms: [blackListFilter]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleWare) => getDefaultMiddleWare({ serializableCheck: false })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
