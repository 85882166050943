import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import { Client } from '../Types';

type Props = { client: Client };

const ClientTags = ({ client }: Props) => {
  return (
    <Stack
      direction="horizontal"
      gap={2}
      style={{
        marginBottom: 5
      }}>
      {client.delinquent && (
        <>
          <Badge bg="danger" pill>
            No Working CC as of: {new Date(client.delinquent).toLocaleDateString()}
          </Badge>
        </>
      )}
      {client.eligibleForTowing && (
        <Badge bg="danger" pill>
          Eligible for Towing
        </Badge>
      )}
    </Stack>
  );
};

export default ClientTags;
