import React from 'react';
import Container from 'react-bootstrap/Container';
import './App.css';
import { Outlet } from '@tanstack/react-location';
import GlobalFooter from './Components/GlobalFooter';
import NavBar from './Components/NavBar';
import ResultsModal from './Components/ResultsModal';
import GlobalLoading from './Components/GlobalLoading';
import AuthProvider from './providers/AuthProvider';
import { Toaster } from 'react-hot-toast';
import SuccessDialog from './Components/dialogs/SuccessDialog';
import ErrorDialog from './Components/dialogs/ErrorDialog';
import FreshBooksAuthorization from './Components/FreshBooksAuthorization';

function App() {
  // const { isLoading } = useAuth0();
  return (
    <AuthProvider>
      <NavBar />
      <Toaster position="top-right" reverseOrder={false} />
      <Container className="App">
        <GlobalLoading />
        {/* {!!processesArray.length && <LoadingPage />} */}
        <ResultsModal />
        <Outlet />
      </Container>
      <GlobalFooter />
      <ErrorDialog />
      <SuccessDialog />
      <FreshBooksAuthorization />
    </AuthProvider>
  );
}

export default App;
