import React, { useRef, useState } from 'react';
import { updateTicketFromClient } from '../../ReduxSlices/clientsSlice';
import { Transition } from 'react-transition-group';
import Badge from 'react-bootstrap/Badge';
import { ClientTicket, WEFResponse } from '../../Types';
import { CheckSquare, Square } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../hooks';
import { customAxiosFetch, customFetch } from '../../helpers/customFetch';
import IgnoreTicketConfirmationPopup from './IgnoreTicketConfirmationPopup';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';

const ticketStages = {
  invoice: { string: () => 'Invoice Stage', color: 'red' },
  estimate: { string: () => 'Estimate Stage', color: 'orange' },
  zeroedOut: {
    string: (ticket: ClientTicket) =>
      'Zeroed Out on ' + new Date(ticket.invoice.zeroedOutAt).toLocaleDateString(),
    color: 'lightgreen'
  },
  paid: { string: () => 'Paid', color: 'lightgrey' }
};

interface Props {
  ticket: ClientTicket;
  index: number;
  toggleInclusionInTicketIdCart?: (_: string) => void;
  ticketCart?: string[] | null;
  removeTicket?: () => void;
}

function Ticket({ ticket, index, toggleInclusionInTicketIdCart, ticketCart, removeTicket }: Props) {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);

  const toggleIgnoreTicket = () => {
    setShow(false);
    customFetch({
      endpoint: '/Ticket',
      callback: (r: any) => {
        setShow(false);
        return dispatch(updateTicketFromClient(r));
      },
      config: {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...ticket,
          ignoreTicket: !ticket.ignoreTicket
        })
      }
    });
  };

  const getStage = () => {
    if (!ticket.invoice) {
      return 'estimate';
    }
    if (ticket.invoice.zeroedOutAt) {
      return 'zeroedOut';
    } else {
      if (ticket.invoice.chargeAttempts && ticket.invoice.chargeAttempts.find((ca) => ca.success)) {
        return 'paid';
      } else {
        return 'invoice';
      }
    }
  };

  const defaultStyle = {
    transition: `opacity 500ms ease-in-out`,
    opacity: 0.25
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0.25 },
    exited: { opacity: 0.25 }
  };

  const nodeRef = useRef(null);

  function handleTicketSelect() {
    toggleInclusionInTicketIdCart(ticket.id);
  }

  const handleDelete = async () => {
    await customAxiosFetch<WEFResponse<string>>({
      endpoint: '/Ticket/' + ticket.id,
      callback: (r: any) => {
        if (r.status === 403) {
          toast.error('You do not have permission to delete tickets');
        } else {
          return removeTicket();
        }
      },
      config: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      },
      processDescription: 'Deleting Ticket'
    });
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const confirmIgnoreTicket = () => {
    setShow(true);
  };

  return (
    <>
      <IgnoreTicketConfirmationPopup
        show={show}
        onCloseModal={handleCloseModal}
        onToggleIgnoreTicket={toggleIgnoreTicket}
      />
      <tr
        style={{
          backgroundColor: ticketStages[getStage()].color,
          ...(index === 1 ? { borderTopWidth: 3, borderTopColor: 'black' } : {})
        }}>
        <td>{index}</td>
        <td>{ticket.plateNumber}</td>
        <td>{ticket.dateIssued}</td>
        <td>{new Date(ticket.insertedAt).toLocaleDateString()}</td>
        <td>{ticket.summonsNumber}</td>
        <td>{ticket.penaltyAmount !== 0 ? '$' + ticket.penaltyAmount : '-'}</td>
        <td>${ticket.amountOutstanding}</td>
        {ticketCart ? (
          <td>
            {ticketCart.includes(ticket.id) ? (
              <CheckSquare onClick={handleTicketSelect} />
            ) : (
              <Square onClick={handleTicketSelect} />
            )}
          </td>
        ) : (
          <td
            width={50}
            className={'text-center'}
            style={{
              cursor: ticket.violationStatus === 'HEARING HELD-NOT GUILTY' ? 'not-allowed' : 'auto'
            }}
            onClick={() =>
              ticket.violationStatus !== 'HEARING HELD-NOT GUILTY' && confirmIgnoreTicket()
            }>
            {ticket.violationStatus === 'HEARING HELD-NOT GUILTY' ? (
              <Badge className={'bg-secondary'}>Dismissed</Badge>
            ) : (
              <Transition nodeRef={nodeRef} in={ticket.ignoreTicket} timeout={500}>
                {(state) => (
                  <div
                    ref={nodeRef}
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                      cursor: 'pointer'
                    }}>
                    <Badge className={ticket.ignoreTicket ? 'bg-danger' : 'bg-secondary'}>
                      {ticket.ignoreTicket ? 'Ignored' : '-'}
                    </Badge>
                  </div>
                )}
              </Transition>
            )}
          </td>
        )}
        <td>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </td>
      </tr>
    </>
  );
}

export default Ticket;
