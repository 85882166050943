import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';
import { Client, ClientTicket, ClientTicketList } from '../Types';
import { Button } from 'react-bootstrap';
import { setResults } from '../ReduxSlices/resultsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { customFetch } from '../helpers/customFetch';
import UnZeroOutTicketsButton from '../Components/Buttons/UnZeroOutTicketsButton';
import TicketsTable from './TicketsTable';
import { store } from '../Store';
import { updateClientInRedux } from '../ReduxSlices/clientsSlice';

type TabKeys = 'estimates' | 'invoices' | 'delinquent' | 'waitingToBeInvoiced';
type TicketsTabCount = {
  estimates: number;
  invoices: number;
  waitingToBeInvoiced: number;
  delinquent: number;
};

function ClientTickets({ client }: { client: Client }) {
  const [key, setKey] = useState<TabKeys>('estimates');
  const [ticketIdCart, setTicketIdCart] = useState<string[] | null>(null);
  const selectedClientId = useAppSelector((state) => state.clients.selectedClientId);
  const dispatch = useAppDispatch();
  const [ticketsTabCount, setTicketsTabCount] = useState<TicketsTabCount>();

  useEffect(() => {
    updateClientDataInRdux(selectedClientId, []);
    fetchClientTicketsByStatus(client.id, key);
  }, []);

  const fetchClientTicketsByStatus = (clientId: string, ticketStatus: string) => {
    customFetch({
      endpoint: `/Ticket/GetClientTickets/${clientId}/${ticketStatus}`,
      processDescription: 'Fetching Tickets, please be patient.',
      callback: (result: ClientTicketList) => {
        updateClientDataInRdux(clientId, result.tickets);
        const { totalEstimates, totalInvoices, totalWaitingToBeInvoiced, totalDelinquent } = result;
        setTicketsTabCount({
          estimates: totalEstimates,
          invoices: totalInvoices,
          waitingToBeInvoiced: totalWaitingToBeInvoiced,
          delinquent: totalDelinquent
        });
      }
    });
  };

  function updateClientDataInRdux(clientId: string, tickets: ClientTicket[]) {
    const reduxState = store.getState();
    const updatedClient = {
      ...reduxState.clients.allClientsList.find((client) => client.id === clientId),
      tickets: tickets
    };
    store.dispatch(updateClientInRedux(updatedClient));
  }

  function handleChargeClick() {
    const processDescription: string = 'Charging Tickets';
    if (ticketIdCart && ticketIdCart.length > 0) {
      customFetch({
        endpoint: '/Payment/ChargeTickets',
        processDescription: processDescription,
        callback: () => {
          clearTicketIdCart();
          dispatch(
            setResults({
              process: processDescription,
              results: 'Success!'
            })
          );
        },
        config: {
          method: 'POST',
          body: JSON.stringify({
            clientId: selectedClientId,
            ticketIds: ticketIdCart
          })
        },
        skipParseBody: true
      });
    }
  }

  const handleEnableSelectingTickets = () => {
    if (key === 'estimates') {
      handleSelectTab('invoices');
    }
    setTicketIdCart([]);
  };

  function toggleInclusionInTicketIdCart(ticketId: string) {
    if (ticketIdCart.includes(ticketId)) {
      setTicketIdCart((currentCartContent) =>
        [...currentCartContent].filter((e) => e !== ticketId)
      );
    } else {
      setTicketIdCart((currentCartContent) => [...currentCartContent, ticketId]);
    }
  }

  function clearTicketIdCart() {
    setTicketIdCart(null);
  }

  function handleSelectTab(key: TabKeys) {
    setKey(key);
    updateClientDataInRdux(selectedClientId, []);
    fetchClientTicketsByStatus(selectedClientId, key);
  }

  return (
    <>
      {!!ticketIdCart?.length &&
        (key === 'delinquent' ? (
          <UnZeroOutTicketsButton
            clientId={client.id}
            tickets={client.tickets
              .filter((ticket) => ticketIdCart.includes(ticket.id))
              .map((ticket) => ticket)}
          />
        ) : (
          <Button size="sm" variant={'danger'} onClick={handleChargeClick}>
            Charge These Tickets
          </Button>
        ))}
      {ticketIdCart ? (
        <Button size="sm" variant="secondary" className="ms-2" onClick={clearTicketIdCart}>
          Cancel Selection
        </Button>
      ) : (
        <Button
          size="sm"
          variant="outline-warning"
          className="ms-2"
          onClick={handleEnableSelectingTickets}>
          Select Specific Tickets
        </Button>
      )}

      <Tabs
        className="mt-2"
        activeKey={key}
        onSelect={handleSelectTab}
        justify
        mountOnEnter
        unmountOnExit>
        {!ticketIdCart && (
          <Tab
            eventKey="estimates"
            title={
              <h6>
                In Estimates <Badge>{ticketsTabCount?.estimates}</Badge>
              </h6>
            }>
            {client.tickets && client.tickets.length > 0 ? (
              <TicketsTable tickets={client.tickets} />
            ) : (
              'No tickets available'
            )}
          </Tab>
        )}
        <Tab
          eventKey="invoices"
          title={
            <h6>
              In Invoices <Badge>{ticketsTabCount?.invoices}</Badge>
            </h6>
          }>
          {client.tickets && client.tickets.length > 0 ? (
            <TicketsTable
              tickets={client.tickets}
              toggleInclusionInTicketIdCart={toggleInclusionInTicketIdCart}
              ticketCart={ticketIdCart}
            />
          ) : (
            'No tickets available'
          )}
        </Tab>
        {!ticketIdCart && (
          <Tab
            eventKey="waitingToBeInvoiced"
            title={
              <h6>
                Waiting to be invoiced <Badge>{ticketsTabCount?.waitingToBeInvoiced}</Badge>
              </h6>
            }>
            {client.tickets && client.tickets.length > 0 ? (
              <TicketsTable
                tickets={client.tickets}
                toggleInclusionInTicketIdCart={toggleInclusionInTicketIdCart}
                ticketCart={ticketIdCart}
              />
            ) : (
              'No tickets available'
            )}
          </Tab>
        )}
        <Tab
          eventKey="delinquent"
          title={
            <h6>
              Delinquent <Badge>{ticketsTabCount?.delinquent}</Badge>
            </h6>
          }>
          {client.tickets && client.tickets.length > 0 ? (
            <TicketsTable
              tickets={client.tickets}
              toggleInclusionInTicketIdCart={toggleInclusionInTicketIdCart}
              ticketCart={ticketIdCart}
            />
          ) : (
            'No tickets available'
          )}
        </Tab>
      </Tabs>
    </>
  );
}

export default ClientTickets;
